import React from "react";
import {Empty} from "google-protobuf/google/protobuf/empty_pb";
import {Metadata, Status} from "grpc-web";

import {Event} from "./generated/proto/service/eventstream_pb";
import {EventStreamClient} from "./generated/proto/service/EventstreamServiceClientPb";

const client = new EventStreamClient("http://localhost:8080");

export const App: React.FC = () => {
  React.useEffect(() => {
    const stream = client.subscribe(new Empty());
    stream
      .on("error", (err: Error) => console.log(err))
      .on("status", (status: Status) => console.log(status))
      .on("metadata", (metadata: Metadata) => console.log(metadata))
      .on("data", (resp: Event) => console.log(resp))
      .on("end", console.log("Connection terminated."));
  }, []);

  return (
    <div>Hello, World!</div>
  )
};
